import React from 'react';

import { Breadcrumbs, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import WarningBanner from './warning-banner';

const useStyles = makeStyles(() => ({
    disabled: {
        pointerEvents: 'none',
        color: '#9B9B9D',
    },
    linkBreadcrumb: {
        color: '#1F1F21',
        '&:hover': {
            color: '#1F1F21',
        },
    },
    pageName: {
        marginBottom: '31px',
        marginTop: 0,
    },
    flexLeft: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    breadcrumbContainer: {
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: '13px',
        minHeight: '34px',
    },
}));

export function BreadcrumbNavigation({ items, rightElement, pageName }) {
    const classes = useStyles();

    // Get the current date
    const currentDate = new Date();

    // Set the target date to 23.05.2024 10:00 CET
    const targetDate = new Date('2024-05-23T10:00:00+02:00');

    return (
        <div className={classes.flexLeft}>
            <div className={classNames(classes.breadcrumbContainer, 'flex-horizontal')}>
                <Breadcrumbs>
                    {items.map((item, index) => {
                        return (
                            <Link
                                key={index}
                                className={
                                    item.disabled ? classes.disabled : classes.linkBreadcrumb
                                }
                                underline="none"
                                href={item.href}
                            >
                                {item.text || ''}
                            </Link>
                        );
                    })}
                </Breadcrumbs>
                {rightElement && rightElement}
            </div>
            {currentDate < targetDate && (
                <WarningBanner
                    text="The Downtown Neighbouring Rights portal will be down for scheduled maintenance
                        on 23.05 from 09:30 CET to 10:00 CET. Thank you for your understanding"
                />
            )}
            <Typography variant="h4" className={classes.pageName}>
                {pageName}
            </Typography>
        </div>
    );
}
