import React, { useEffect } from 'react';

import { changeStateByKey, changeStateByNestedKeyNew } from '@actions/fugaActions';
import { getUsersLookupAPI } from '@api/usersController';
import CommonFiltersContainer from '@common/CommonFiltersContainer';
import { TableFilters } from '@common/TableFilters/TableFilters';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import Icon from '@mui/material/Icon';
import { FilterMenuTypes } from '@utils/enum';
import { useDispatch, useSelector } from 'react-redux';

function EventsPageFiltersWrapper({ tableReloadCb }) {
    const dispatch = useDispatch();
    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    const clearAllFilters = () => {
        dispatch(changeStateByKey('filters', {}));
        tableReloadCb();
    };

    const handleFilterClick = (currentValue, newValue) => {
        const updatedValue = currentValue === newValue ? null : newValue;
        dispatch(changeStateByNestedKeyNew('filters', 'asyncEventType', updatedValue));
    };

    // Clear filters on mount
    useEffect(() => {
        dispatch(changeStateByKey('filters', {}));
    }, [dispatch]);

    const menuItems = [
        {
            label: 'Exports',
            filterField: 'exports',
            type: FilterMenuTypes.STATIC,
            filterNameInRequest: 'exports',
            chipIcon: (
                <DownloadForOfflineOutlinedIcon
                    sx={{
                        height: '18px',
                        width: '18px',
                    }}
                />
            ),
            value: filtersModel?.asyncEventType === 'EXPORT' ? 'Exports' : '',
            handleFilterClick: async () =>
                handleFilterClick(filtersModel?.asyncEventType, 'EXPORT'),
        },
        {
            label: 'Ingestions',
            filterField: 'ingestions',
            type: FilterMenuTypes.STATIC,
            filterNameInRequest: 'ingestions',
            disabled: true,
            chipIcon: (
                <Icon
                    sx={{
                        fontSize: '18px',
                    }}
                >
                    database_upload
                </Icon>
            ),
            value: filtersModel?.asyncEventType === 'INGESTION' ? 'Ingestions' : '',
            handleFilterClick: async () =>
                handleFilterClick(filtersModel?.asyncEventType, 'INGESTION'),
        },
        {
            label: 'User',
            filterField: 'adminUsernames',
            type: FilterMenuTypes.AUTOCOMPLETE,
            filterNameInRequest: 'adminUsernames',
            value: filtersModel?.adminUsernames?.valuesToShow || [],
            getSuggestions: (text, signal) => getUsersLookupAPI(text, signal),
            placeholder: 'Search by user',
            lookupField: 'usernames',
        },
    ];

    return (
        <CommonFiltersContainer
            innerFiltersComponent={
                <TableFilters
                    tableReloadCb={tableReloadCb}
                    menuItems={menuItems}
                    clearAllFilters={clearAllFilters}
                />
            }
        />
    );
}

export default EventsPageFiltersWrapper;
