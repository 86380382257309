import React from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { Check } from '@mui/icons-material';
import { Button, ButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    checkIcon: {
        width: '15px',
        height: '15px',
        marginRight: '8px',
    },
    claimMethodBtnGroup: {
        display: 'flex',
        justifyContent: 'flex-right',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
    },
}));

export function ClaimMethodSelector({
    claimMethodValue,
    setClaimMethodValue,
    getAssetData,
    pageModel,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const getButtonConfig = (radioValue, targetValue) => {
        return radioValue === targetValue
            ? { color: 'primary', variant: 'contained', className: classes.activeButton }
            : { color: '', variant: 'outlined' };
    };

    const handleClaimMethodSelection = ({ currentTarget }) => {
        const value = currentTarget.name;
        dispatch(changeStateByNestedKey('cmoPage', 'claimMethod', value));
        const filterPattern = value === 'el' ? 'EXCLUSIVE_LICENSE' : 'AGENT';
        dispatch(changeStateByNestedKey('queryParams', 'claimMethod', filterPattern));
        setClaimMethodValue({ value: filterPattern, radioValue: value });
        getAssetData();
    };

    const isExclusiveLicenseDisabled =
        pageModel.cmoInfo?.claimMethodType?.length === 1 &&
        pageModel.cmoInfo?.claimMethodType?.includes('AGENT');
    const isAgentDisabled =
        pageModel.cmoInfo?.claimMethodType?.length === 1 &&
        pageModel.cmoInfo?.claimMethodType?.includes('EXCLUSIVE_LICENSE');

    return (
        <ButtonGroup
            disableElevation
            className={classes.claimMethodBtnGroup}
            sx={{
                '& .MuiSvgIcon-root': {
                    color: '#fff',
                },
            }}
        >
            <Button
                {...getButtonConfig(claimMethodValue.radioValue, 'el')}
                name="el"
                onClick={handleClaimMethodSelection}
                disabled={isExclusiveLicenseDisabled}
            >
                {claimMethodValue.radioValue === 'el' && <Check className={classes.checkIcon} />}
                Exclusive license
            </Button>
            <Button
                {...getButtonConfig(claimMethodValue.radioValue, 'agent')}
                name="agent"
                onClick={handleClaimMethodSelection}
                disabled={isAgentDisabled}
            >
                {claimMethodValue.radioValue === 'agent' && <Check className={classes.checkIcon} />}
                Agent
            </Button>
        </ButtonGroup>
    );
}
