import React, { useEffect, useState } from 'react';

import { createOrUpdateCmoRegistration } from '@actions/apiActions';
import { getCmoIssuedIdsByClientId } from '@actions/cmoIssuedId';
import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { createCmoIssuedIdAPI, editCmoIssuedIdAPI } from '@api/clientController';
import NewCmoConnectionDatesAndClaimMethods from '@common/modals/NewCmoConnectionModal/NewCmoConnectionDatesAndClaimMethods';
import NewCmoConnectionRegionItem from '@common/modals/NewCmoConnectionModal/NewCmoConnectionRegionItem';
import { Alert } from '@mui/material';
import {
    Button,
    Dialog,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Typography,
    Divider,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { connect, useDispatch } from 'react-redux';

const NewCmoConnectionModal = ({
    clientId,
    showTerritoryModal,
    setShowTerritoryModal,
    territories,
    setTerritories,
    groupedTerritories,
    isEditing,
    territoriesPropModel,
    customData,
}) => {
    const dispatch = useDispatch();
    const [selectedStartDate, handleStartDateChange] = useState(new Date());
    const [selectedEndDate, handleEndDateChange] = useState(new Date());
    const [claimMethod, setClaimMethod] = useState('agent');
    const [dateError, setDateError] = useState({
        start: { error: false, msg: '' },
        end: { error: false, msg: '' },
    });
    const [issuedId, setIssuedId] = useState('');

    useEffect(() => {
        dispatch(getCmoIssuedIdsByClientId(clientId));
        if (isEditing) {
            handleEndDateChange(parseISO(customData.selectedRowDataItem.endDate));
            handleStartDateChange(parseISO(customData.selectedRowDataItem.startDate));
        } else {
            dispatch(
                changeStateByNestedKey(customData.selectedCmoStoreName, 'selectedCmoId', undefined),
            );
        }
    }, [customData.selectedRowDataItem, isEditing]);

    useEffect(() => {
        if (!isEditing) {
            setTerritories({});
        }
    }, []);

    useEffect(() => {
        if (Object.keys(territoriesPropModel.editableDealInfo).length > 0) {
            handleStartDateChange(parseISO(territoriesPropModel.editableDealInfo.startDate));
            handleEndDateChange(parseISO(territoriesPropModel.editableDealInfo.endDate));
        }
    }, [territoriesPropModel.editableDealInfo.startDate]);

    const handleClose = () => {
        setShowTerritoryModal(false);
        dispatch(
            changeStateByNestedKey(customData.selectedCmoStoreName, 'modalValidations', {
                msgTerritory: '',
                msgDate: '',
            }),
        );
    };

    const closeAndReloadTable = () => {
        handleClose();
        if (customData.onTableReload) {
            dispatch(customData.onTableReload.reloadCb(customData.onTableReload.params));
        }
    };

    const getStartdateValue = () => {
        return true;
    };

    const handleSave = () => {
        if (customData?.selectedRowDataItem?.registrationStatus === 'EXCLUSIVE_LICENSE_DEAL') {
            handleClose();
        } else if (selectedStartDate && selectedEndDate && selectedStartDate > selectedEndDate) {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'End date cannot be before start date',
                    severity: 'error',
                }),
            );
        } else if (!(selectedStartDate && selectedEndDate)) {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Please Fill in all the fields',
                    severity: 'error',
                }),
            );
        } else {
            let errObj = {};
            let allTerritoriesCount = 0;
            groupedTerritories.forEach((el) => (allTerritoriesCount += el.territories.length));
            const selectedTerritories = Object.values(territories)
                .map((territoriesByRegion) => territoriesByRegion)
                .flat()
                .join();
            const dealTerritoriesContent =
                selectedTerritories.split(',').length === allTerritoriesCount
                    ? 'WW'
                    : selectedTerritories;
            setDateError(errObj);
            const objToSave = {
                ...(getStartdateValue() && {
                    startDate: format(selectedStartDate, 'yyyy-MM-dd'),
                }),
                endDate: format(selectedEndDate, 'yyyy-MM-dd'),
                territory: dealTerritoriesContent,
                ...(isEditing && {
                    clientCmoRegistrationId: customData.selectedRowDataItem.cmoRegId,
                }),
                ...(!isEditing && { cmoId: customData.selectedCmoId }),
                ...(!isEditing && {
                    claimMethod: claimMethod === 'agent' ? 'AGENT' : 'EXCLUSIVE_LICENSE',
                }),
                ...(!isEditing && {
                    clientId: customData.dataModel.clientId,
                }),
            };
            const issuedIdBody = {
                cmoId: customData.selectedCmoId,
                clientId,
                cmoIssuedId: issuedId,
            };
            if (isEditing) {
                dispatch(
                    createOrUpdateCmoRegistration(objToSave, {
                        handleCloseCb: closeAndReloadTable,
                        update: true,
                        storeName: customData.selectedCmoStoreName,
                    }),
                );
                if (customData.selectedCmoStoreName !== 'rhData') editCmoIssuedIdAPI(issuedIdBody);
            } else {
                dispatch(
                    createOrUpdateCmoRegistration([objToSave], {
                        handleCloseCb: closeAndReloadTable,
                        storeName: customData.selectedCmoStoreName,
                    }),
                );
                if (customData.selectedCmoStoreName !== 'rhData') {
                    if (customData.hasIssuedId) {
                        editCmoIssuedIdAPI(issuedIdBody);
                    } else {
                        createCmoIssuedIdAPI(issuedIdBody);
                    }
                }
            }
        }
    };

    const setAllTerritories = () => {
        let newTerritories = {};
        groupedTerritories.map(
            (territoryGroup) =>
                (newTerritories = {
                    ...newTerritories,
                    [territoryGroup.region.id]: territoryGroup.territories.map((t) => t.id),
                }),
        );
        setTerritories(newTerritories);
    };

    const toggleWorld = () => {
        if (allSelected()) {
            setTerritories({});
        } else {
            setAllTerritories();
        }
    };

    const noneSelected = () => {
        return (
            Object.entries(territories).every((item) => item[1].length === 0) ||
            !customData.selectedCmoId
        );
    };

    const allSelected = () => {
        return groupedTerritories.every((gt) => {
            const matchedTerritory = Object.entries(territories).find((region) => {
                return gt.region.id === region[0];
            });
            return matchedTerritory && matchedTerritory[1].length === gt.territories.length;
        });
    };

    const anySelected = () => {
        return (
            Object.values(territories).length > 0 &&
            Object.values(territories).some((territory) => territory.length > 0)
        );
    };

    return (
        <div>
            <Dialog onClose={handleClose} open={showTerritoryModal} fullWidth maxWidth="md">
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {`${isEditing ? 'Edit' : 'Add'} CMO to ${
                        customData.dataModel.legalCompanyName || customData.dataModel.firstName
                    } ${customData.dataModel?.lastName || ''} `}
                </DialogTitle>
                <DialogContent dividers style={{ maxHeight: '50%' }}>
                    <NewCmoConnectionDatesAndClaimMethods
                        selectedCmoId={customData.selectedCmoId}
                        selectedStartDate={selectedStartDate}
                        handleStartDateChange={handleStartDateChange}
                        selectedEndDate={selectedEndDate}
                        handleEndDateChange={handleEndDateChange}
                        isEditing={isEditing}
                        errorOperations={{
                            dateError,
                            setDateError,
                        }}
                        dateErrMsg={customData.modalValidations.msgDate}
                        isPerformer={customData.isPerformer}
                        cmoIssuedIds={customData.cmoIssuedIds}
                        setIssuedId={setIssuedId}
                        issuedId={issuedId}
                        storeName={customData.selectedCmoStoreName}
                        claimMethod={claimMethod}
                        setClaimMethod={setClaimMethod}
                        isEndDateDisabled={
                            customData?.selectedRowDataItem?.lockedForEdit &&
                            customData?.selectedRowDataItem?.registrationStatus ===
                                'EXCLUSIVE_LICENSE_DEAL'
                        }
                        isStartDateDisabled={
                            customData?.selectedRowDataItem?.registrationStatus ===
                            'EXCLUSIVE_LICENSE_DEAL'
                        }
                        claimMethodDefault={
                            customData?.selectedRowDataItem?.claimMethod === 'EXCLUSIVE_LICENSE'
                                ? 'Exclusive License'
                                : 'Agent'
                        }
                    />
                    <Divider variant="inset" style={{ margin: '25px 0 29px 0' }} />
                    <Typography variant="subtitle1" style={{ marginBottom: '27.17px' }}>
                        Territories
                    </Typography>
                    <Alert
                        variant="outlined"
                        severity="error"
                        hidden={!customData.modalValidations.msgTerritory}
                        sx={{
                            backgroundColor: '#FFEBEE',
                        }}
                    >
                        {customData.modalValidations.msgTerritory}
                    </Alert>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allSelected()}
                                onChange={() => toggleWorld()}
                                name="World"
                                color="primary"
                                indeterminate={allSelected() ? false : anySelected()}
                                disabled={customData?.selectedRowDataItem?.lockedForEdit}
                            />
                        }
                        label="World"
                    />
                    {groupedTerritories.map((territoryGroup) => (
                        <NewCmoConnectionRegionItem
                            key={territoryGroup.region.id}
                            territorySelection={territoryGroup.territories}
                            territories={territories}
                            setTerritories={setTerritories}
                            region={territoryGroup.region}
                            isDisabled={customData?.selectedRowDataItem?.lockedForEdit}
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={noneSelected()}
                        variant="secondary"
                        onClick={handleSave}
                        color="primary"
                    >
                        {customData?.selectedRowDataItem?.registrationStatus ===
                        'EXCLUSIVE_LICENSE_DEAL'
                            ? 'Close'
                            : 'Save & Close'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default connect((store) => ({
    territoriesPropModel: store.fugaReducers.territories,
}))(NewCmoConnectionModal);
