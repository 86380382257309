import cerberusUtils from '@utils/cerberusUtils';
import {
    handleCommonResponses,
    parseErrorResponse,
    parseResponse,
    showToastError,
} from '@utils/apiUtils';
import { toast } from 'react-toastify';

const { getCerberusToken } = cerberusUtils;

class Fetch {
    static defaultOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    static defaultMessages = {
        errorMessage: 'An error has occurred',
        pendingMessage: 'Loading, please wait...',
        successMessage: 'Request completed successfully',
    };

    static async fetchWrapper(url, options = {}) {
        try {
            options = { ...this.defaultOptions, ...options };

            if (!window.location.hostname.includes('localhost')) {
                options.credentials = 'include';
            }

            const token = getCerberusToken();
            options.headers = {
                ...options.headers,
                ...(token && { 'x-nr-cerberus-token': token }),
                Origin: window.location.origin,
            };

            const res = await fetch(url, options);

            handleCommonResponses(res);

            return res;
        } catch (err) {
            if (err.name === 'AbortError') {
                throw new Error('FetchAborted');
            } else {
                console.error('Failed to fetch, reason:', err);
                throw err;
            }
        }
    }

    static async fetchWithErrorToast({
        url,
        options = this.defaultOptions,
        downloadResp = false,
        errorMessage = this.defaultMessages.errorMessage,
        signal,
    }) {
        try {
            const res = await this.fetchWrapper(url, { ...options, signal });

            if (!res.ok) {
                const error = await parseErrorResponse(res);
                showToastError(error.message);
                throw error;
            }

            return await parseResponse(res, downloadResp);
        } catch (error) {
            if (error.message === 'FetchAborted') {
                console.error('Fetch request was cancelled:', error);
                // Decide whether to rethrow or handle silently
            } else {
                showToastError(error.message || errorMessage);
                throw error;
            }
        }
    }

    static async fetchWithToasts({
        url,
        options = this.defaultOptions,
        downloadResp = false,
        pendingMessage = this.defaultMessages.pendingMessage,
        successMessage = this.defaultMessages.successMessage,
        errorMessage = this.defaultMessages.errorMessage,
    }) {
        return toast.promise(
            (async () => {
                const res = await this.fetchWrapper(url, options);

                if (!res.ok) {
                    const errorData = await parseErrorResponse(res);
                    throw new Error(errorData.message || 'An unexpected error occurred');
                }

                return await parseResponse(res, downloadResp);
            })(),
            {
                pending: pendingMessage,
                success: successMessage,
                error: (data) => {
                    const errorDetail = data?.message || 'An unexpected error occurred';
                    return `${errorMessage}: ${errorDetail}`;
                },
            },
        );
    }

    static async fetchWithToastsCustomSuccess({
        url,
        options = this.defaultOptions,
        downloadResp = false,
        pendingMessage = this.defaultMessages.pendingMessage,
        errorMessage = this.defaultMessages.errorMessage,
    }) {
        const toastId = toast.loading(pendingMessage);

        try {
            const res = await this.fetchWrapper(url, options);

            if (!res.ok) {
                const error = await parseErrorResponse(res);

                const detailedErrorMessage =
                    error?.data?.[0]?.message || error.message || 'An unexpected error occurred';

                toast.update(toastId, {
                    render: detailedErrorMessage,
                    type: 'error',
                    isLoading: false,
                    autoClose: 5000,
                });

                throw new Error(detailedErrorMessage);
            }

            const result = await parseResponse(res, downloadResp);

            return { data: result.data, toastId };
        } catch (error) {
            console.error('Request failed:', error);

            toast.update(toastId, {
                render: `${errorMessage}: ${error.message}`,
                type: 'error',
                isLoading: false,
                autoClose: 5000,
            });

            throw error;
        }
    }
}

export default Fetch;
