import React, { useEffect, useState } from 'react';

import { getCmoConnectionsByClientId } from '@actions/apiActions';
import { changeStateByNestedKey } from '@actions/fugaActions';
import CommonDropDownButton from '@common/CommonDropDownButton';
import NewCmoConnectionModal from '@common/modals/NewCmoConnectionModal/NewCmoConnectionModal';
import StatementsTable from '@common/StatementsTable';
import { items } from '@data/constants';
import {
    DEFAULT_TAB_CMOS,
    DEFAULT_TAB_MAIN_DETAILS,
    DEFAULT_TAB_REPERTORIRES,
    DEFAULT_TAB_STATEMENTS,
    PERMISSIONS_REPERTOIRE,
    PERMISSIONS_STATEMENTS,
} from '@data/globalConstants';
import { Tabs, Tab, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import utils from '@utils/utils';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ClientCmoConnectionTable from './ClientCmoConnectionTable';
import ClientMainDetailsNavigation from './ClientMainDetailsNavigation';
import Repertoires from './Repertoires';

const useStyles = makeStyles({
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '16px 0',
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ width: '100%' }}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

function ClientInfoNavigation() {
    const dispatch = useDispatch();
    const [value, setValue] = useState(
        utils.checkItemRolesAndPermissions(items.tabs.mainDetails) ? 0 : 2,
    );
    const classes = useStyles();
    const params = useParams();
    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [territories, setTerritories] = useState([]);
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);

    const panes = {
        [DEFAULT_TAB_MAIN_DETAILS]: {
            label: 'MAIN DETAILS',
            id: 0,
            isVisible: utils.checkItemRolesAndPermissions(items.tabs.mainDetails),
        },
        [DEFAULT_TAB_REPERTORIRES]: {
            label: 'REPERTOIRE',
            id: 1,
            isVisible:
                utils.checkPermissionsOnPage(
                    rhDataModel.detailsData.permissions || [],
                    PERMISSIONS_REPERTOIRE,
                ) || utils.checkItemRolesAndPermissions(items.tabs.repertories),
        },
        [DEFAULT_TAB_STATEMENTS]: {
            label: 'STATEMENTS',
            id: 2,
            isVisible:
                utils.checkPermissionsOnPage(
                    rhDataModel.detailsData.permissions || [],
                    PERMISSIONS_STATEMENTS,
                ) || utils.checkItemRolesAndPermissions(items.tabs.statements),
        },
        [DEFAULT_TAB_CMOS]: {
            label: 'CMOS',
            id: 3,
            isVisible: utils.checkItemRolesAndPermissions(items.tabs.cmos),
        },
    };

    useEffect(() => {
        let tabId = 0;
        if (rhDataModel.detailsData.permissions) {
            const defaultTabId = utils.getDefaultTabIdForClientPage(
                items.tabs.statements,
                panes,
                rhDataModel.detailsData.permissions || [],
            );
            const tabIdFromClientsListRegistrationsColumn = utils.getTabIdByNameForClientPage(
                rhDataModel.selectedTab,
                panes,
            );
            tabId = defaultTabId || tabIdFromClientsListRegistrationsColumn;
        }

        if (tabId) {
            setValue(tabId);
        }
    }, [rhDataModel.detailsData.permissions]);

    const openModal = () => {
        setShowTerritoryModal(true);
    };

    const menuItemsList = [
        {
            title: 'Add single CMO',
            onClickClb: openModal,
        },
    ];

    const handleChange = (event, newValue) => {
        if (newValue !== panes.details.id) {
            dispatch(changeStateByNestedKey('clientPage', 'isEditBtnVisible', false));
        }
        setValue(newValue);
    };

    const getTabsContent = () => {
        const finalTabs = [];
        for (const [tabName, tab] of Object.entries(panes)) {
            if (tab.isVisible)
                finalTabs.push(
                    <Tab key={panes[tabName].id} value={tab.id} label={panes[tabName].label} />,
                );
        }
        return finalTabs;
    };

    return (
        <>
            <Box xs={{ borderBottom: 1, width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    variant="scrollable"
                >
                    {getTabsContent()}
                </Tabs>
            </Box>
            <TabPanel value={value} index={panes[DEFAULT_TAB_MAIN_DETAILS].id}>
                <ClientMainDetailsNavigation />
            </TabPanel>
            <TabPanel value={value} index={panes[DEFAULT_TAB_REPERTORIRES].id}>
                <Repertoires />
            </TabPanel>
            <TabPanel value={value} index={panes[DEFAULT_TAB_STATEMENTS].id}>
                <StatementsTable />
            </TabPanel>
            <TabPanel value={value} index={panes[DEFAULT_TAB_CMOS].id}>
                <div className={classes.flexEnd}>
                    <CommonDropDownButton
                        btnTitle="ADD CMO"
                        menuItemsList={menuItemsList}
                        customStyles={{ variant: 'secondary' }}
                    />
                </div>
                <ClientCmoConnectionTable />
                {showTerritoryModal && (
                    <NewCmoConnectionModal
                        showTerritoryModal={showTerritoryModal}
                        setShowTerritoryModal={setShowTerritoryModal}
                        territories={territories}
                        setTerritories={setTerritories}
                        groupedTerritories={territoriesModel.groupedTerritories}
                        clientId={params.id}
                        customData={{
                            dealsContainerName: 'clientDealDTO',
                            selectedCmoStoreName: 'rhData',
                            dataModel: rhDataModel.detailsData,
                            selectedCmoId: rhDataModel.selectedCmoId,
                            modalValidations: rhDataModel.modalValidations,
                            isPerformer: { isRH: true },
                            onTableReload: {
                                reloadCb: getCmoConnectionsByClientId,
                                params: [params.id],
                            },
                            cmoIssuedIds: rhDataModel.cmoIssuedIds,
                            hasIssuedId: rhDataModel.hasIssuedId,
                        }}
                    />
                )}
            </TabPanel>
        </>
    );
}

export default connect((r) => r)(ClientInfoNavigation);
