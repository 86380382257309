export const APP_ROUTES = {
    CMO_LIST: '/cmo-list',
    CMO_SELECTION: '/cmo-selection/:id',
    CLIENT_PAGE: '/client-page/:id',
    PERFORMER_PAGE: '/performer-page/:id',
    CMO_PAGE: '/cmo-page/:id/:type',
    CLIENTS: '/clients',
    PERFORMERS: '/performers',
    USERS: '/users',
    EVENTS: '/events',
    MAINTENANCE: '/maintenance',
    HEALTH: '/health',
};

export const BREADCRUMB_TEXTS = {
    USER_MANAGEMENT: 'User Management',
    USERS: 'Users',
    CLIENTS: 'Clients',
    EVENTS: 'Events',
    PERFORMERS: 'Performers',
    CMO_CATALOG: 'CMOs Catalog',
    RIGHTS_HOLDERS: 'Rights Holders',
};
