import React from 'react';

import { getRouteFromUserRole } from '@actions/authActions';
import { items } from '@data/constants';
// eslint-disable-next-line import/extensions
import logo from '@images/branding/DT_logo.png';
import { AppBar, Typography, Toolbar, Button, MenuItem, styled } from '@mui/material';
import Grid from '@mui/material/Grid2';
import utils from '@utils/utils';
import { useLocation, Link } from 'react-router-dom';

import ClientsHeaderBtnDropDownButton from './ClientsHeaderBtnDropDownButton';
import HeaderIconsPanel from './HeaderIconsPanel';

const AppLogoName = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.white,
}));

const LogoParent = styled(Grid)({
    paddingLeft: '4px',
    paddingRight: '10px',
});

const Logo = styled('img')({
    width: 'auto',
    height: '36px',
});

const AppBarStyled = styled(AppBar)({
    width: '100%',
    height: '48px',
    left: 0,
    top: 0,
    boxShadow: 'none',
});

const ButtonStyled = styled(Button)(() => ({
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
}));

const MenuItemsWrapper = styled('nav')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
}));

const LinkStyled = styled(Link)(({ theme, active }) => ({
    height: '100%',
    borderBottom: active ? '2px solid #fff' : 'none',
    display: 'flex',
    alignItems: 'center',
}));

const MenuItemStyled = styled(MenuItem)({
    height: '100%',
    padding: 0,
});

function Header() {
    const location = useLocation();

    const menuItems = [
        {
            label: 'CMOs',
            path: '/cmo-list',
            permission: items.btns.cmoListHeader,
        },
        {
            label: 'Clients',
            component: <ClientsHeaderBtnDropDownButton />,
            permission: items.btns.clientsListHeader,
            activePaths: ['/performers', '/clients'],
        },
        {
            label: 'User Management',
            path: '/users',
            permission: items.btns.userManagementButton,
        },
        {
            label: 'Events',
            path: '/events',
            permission: items.btns.userManagementButton,
        },
    ];

    return (
        <AppBarStyled position="relative">
            <Toolbar variant="dense" disableGutters>
                <Link to={getRouteFromUserRole()}>
                    <LogoParent container alignItems="center">
                        <Grid xs={3}>
                            <Logo src={logo} />
                        </Grid>
                        <Grid
                            container
                            xs={9}
                            direction="column"
                            sx={{
                                alignItems: 'flex-start',
                            }}
                        >
                            <Grid>
                                <AppLogoName
                                    variant="subtitle2"
                                    sx={{ textTransform: 'uppercase' }}
                                    noWrap
                                >
                                    Neighbouring Rights
                                </AppLogoName>
                            </Grid>
                            <Grid>
                                <AppLogoName variant="subtitle2" sx={{ fontSize: 10 }}>
                                    Powered by FUGA
                                </AppLogoName>
                            </Grid>
                        </Grid>
                    </LogoParent>
                </Link>
                <MenuItemsWrapper>
                    {menuItems.map(
                        (item, index) =>
                            utils.checkItemRolesAndPermissions(item.permission) &&
                            (item.component ? (
                                <React.Fragment key={index}>{item.component}</React.Fragment>
                            ) : (
                                <LinkStyled
                                    key={index}
                                    to={item.path}
                                    active={
                                        item.activePaths
                                            ? item.activePaths.includes(location.pathname)
                                            : location.pathname === item.path
                                    }
                                >
                                    <MenuItemStyled>
                                        <ButtonStyled variant="text">{item.label}</ButtonStyled>
                                    </MenuItemStyled>
                                </LinkStyled>
                            )),
                    )}
                </MenuItemsWrapper>
                <HeaderIconsPanel />
            </Toolbar>
        </AppBarStyled>
    );
}

export default Header;
