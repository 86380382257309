import React, { useState } from 'react';

import { Box, Button, ClickAwayListener, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChipTextInput } from '@pages/AssetsCmoPage/ChipTextInput';

const useStyles = makeStyles((theme) => ({
    box: {
        padding: '24px 24px 8px',
        width: '400px',
        minHeight: '147px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    actoinBtns: {
        marginTop: '32px',
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            marginLeft: '24px',
        },
    },
}));

export const FilterMenuDialogWithAutocompleteChips = ({
    defaultValue,
    onConfirm,
    anchorEl,
    closeCb,
    dataItem,
    isOpen,
}) => {
    const [open, setOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(defaultValue || []);

    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
    };

    const handleConfirm = () => {
        onConfirm({
            valuesToShow: selectedOptions.join(', '),
            valuesToSend: selectedOptions,
        });
        handleClose();
    };

    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <ClickAwayListener onClickAway={handleClose}>
                <Box className={classes.box}>
                    <ChipTextInput
                        dataItem={dataItem}
                        open={open}
                        setSelectedOptions={setSelectedOptions}
                        selectedOptions={selectedOptions}
                        setOpen={setOpen}
                    />
                    <div className={classes.actoinBtns}>
                        <Button onClick={handleClose} variant="light" disableElevation>
                            cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleConfirm}
                            disabled={selectedOptions.length < 1}
                        >
                            apply
                        </Button>
                    </div>
                </Box>
            </ClickAwayListener>
        </Popover>
    );
};
