import React, { useState, useEffect } from 'react';

import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { createPerformerClient, editPerformer, getPerformerById } from '@actions/performers';
import DeletePerformerDialog from '@common/DeletePerformerDialog';
import { DeleteOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, withRouter } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    btn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '24px',
        height: '24px',
        color: '#5F5F60',
        marginLeft: '21.02px',
    },
    btnInGroup: {
        marginLeft: '16px',
    },
}));

function PerformerEditButtonGroup({ match }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const {
        //  isEditBtnVisible, // currently is not used disabled as part of NR-1122 second bulet point about trashcan
        isInEditMode,
        isInSaveNewMode,
        isDeletable,
    } = performersModel;
    // const [isDisabled, setIsDisabled] = useState(true);
    const [goToPerformersPage, setGoToPerformersPage] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    // useEffect(() => {
    //     if (isEditBtnVisible || match.params.id === '-1') {
    //         setIsDisabled(false);
    //     }
    // }, []);

    const getSpotifyId = (spotifyId) => {
        const regex = /^(\w{22}(, )?)+$/;
        const arrayOfSpotifyIds = (spotifyId || '').split(', ');

        if (regex.test(spotifyId)) {
            return arrayOfSpotifyIds;
        } else {
            dispatch(
                changeStateByNestedKey(
                    'performers',
                    'spotifyIdErrorText',
                    '22 digits per ID only, for multiple IDs comma separate with a space',
                ),
            );

            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Spotify ID error',
                    severity: 'error',
                }),
            );
            return '';
        }
    };

    const getAliases = (aliases) => {
        if (!aliases.includes(',')) {
            return [aliases];
        } else {
            const regex = /(^([^,]+, )+[^,]+$)/;
            const arrayOfAliases = (aliases || '').split(', ');

            if (regex.test(aliases)) {
                return arrayOfAliases;
            } else {
                dispatch(
                    changeStateByNestedKey(
                        'performers',
                        'aliasesPerformerErrorText',
                        'Multiple entries should be separated with a comma and a space',
                    ),
                );

                dispatch(
                    changeStateByKey('infoMessage', {
                        isVisible: true,
                        message: 'Royalties Contract Reference(s) error',
                        severity: 'error',
                    }),
                );
                return '';
            }
        }
    };

    function checkIfWeAllowEdit() {
        let spotifyIds =
            !performersModel.mainDetailsInptValues.spotifyIds ||
            (performersModel.mainDetailsInptValues.spotifyIds &&
                getSpotifyId(performersModel.mainDetailsInptValues.spotifyIds));

        let clientAliases =
            !performersModel.mainDetailsInptValues.clientAliases ||
            (performersModel.mainDetailsInptValues.clientAliases &&
                getAliases(performersModel.mainDetailsInptValues.clientAliases));

        return clientAliases && spotifyIds;
    }

    const handleNewClientSave = async () => {
        if (!isInEditMode && !isInSaveNewMode) {
            dispatch(changeStateByNestedKey('performers', 'isInEditMode', true));
        } else {
            if (
                !performersModel.mainDetailsInptValues.firstName ||
                !performersModel.mainDetailsInptValues.surname ||
                !performersModel.mainDetailsInptValues.dateOfBirth
            ) {
                dispatch(
                    changeStateByKey('infoMessage', {
                        isVisible: true,
                        message: 'Please enter Name, Aliases, and DOB',
                        severity: 'error',
                    }),
                );
            } else {
                const { mainDetailsInptValues } = performersModel;

                const performerInfo = {
                    firstName: mainDetailsInptValues.firstName,
                    lastName: mainDetailsInptValues.surname,
                    dateOfBirth: mainDetailsInptValues.dateOfBirth,
                    advance: mainDetailsInptValues.advance,
                    aliases: mainDetailsInptValues.aliases,
                    artistNames: mainDetailsInptValues.artistName,
                    contactName: mainDetailsInptValues.contactName,
                    contactEmail: mainDetailsInptValues.contactEmail,
                    contactPhone: mainDetailsInptValues.contactPhone,
                    businessRegisteredAddress: mainDetailsInptValues.businessRegisteredAddress,
                    businessMailingAddress: mainDetailsInptValues.businessMailingAddress,
                    stageName: mainDetailsInptValues.stageName,
                    bandName: mainDetailsInptValues.bandName,
                    dealRate: mainDetailsInptValues.dealRate,
                    nationality: mainDetailsInptValues.nationality,
                    tier: mainDetailsInptValues.tier,
                    placeOfBirth: mainDetailsInptValues.placeOfBirth,
                    countryOfBirth: mainDetailsInptValues.cob,
                    countriesOfResidence: mainDetailsInptValues.cor,
                    rateDuringRecoupment: mainDetailsInptValues.rateDuringRec,
                    rateAfterRecoupment: mainDetailsInptValues.rateAfterRec,
                    postTerm: mainDetailsInptValues.postTerm,
                    currency: mainDetailsInptValues.currency,
                    accountingFrequency: mainDetailsInptValues.accounting,
                    bankAccountNumber: mainDetailsInptValues.bankAccNum,
                    bankAccountPayee: mainDetailsInptValues.bankAccPayee,
                    bankCity: mainDetailsInptValues.bankCity,
                    bankCountry: mainDetailsInptValues.bankCountry,
                    bankIbanNumber: mainDetailsInptValues.iban,
                    bankSwiftNumber: mainDetailsInptValues.bankSwiftNumber,
                    bankName: mainDetailsInptValues.bankName,
                    comments: mainDetailsInptValues.comments,
                    ipn: mainDetailsInptValues.ipn,
                    isMainArtist: mainDetailsInptValues.mainArtist,
                    isSessionArtist: mainDetailsInptValues.sessionArtist,
                    otherIdentifiers: mainDetailsInptValues.otherIdent,
                    spotifyIds: getSpotifyId(mainDetailsInptValues.spotifyIds),
                    clientAliases: getAliases(mainDetailsInptValues.clientAliases),
                    royaltiesClientName: mainDetailsInptValues.royaltiesClientName,
                    permissions: mainDetailsInptValues.clientPermissions,
                };

                if (checkIfWeAllowEdit()) {
                    if (!isInSaveNewMode && isInEditMode) {
                        performerInfo.clientId = performersModel.byClietnId.clientId;
                        performerInfo.performerId = performersModel.byClietnId.performerId;
                        await dispatch(editPerformer(performerInfo));
                        await dispatch(getPerformerById(match.params.id));
                    } else {
                        let createPerformer = {
                            fullname: `${performerInfo.firstName} ${performerInfo.lastName}`,
                            ...(performerInfo.firstName && { firstname: performerInfo.firstName }),
                            ...(performerInfo.lastName && { lastname: performerInfo.lastName }),
                        };
                        let createPerfClient = {
                            companyName: `${performerInfo.firstName} ${performerInfo.lastName}`,
                            ...(performerInfo.firstName && { firstname: performerInfo.firstName }),
                            ...(performerInfo.lastName && { lastname: performerInfo.lastName }),
                        };
                        delete performerInfo.firstName;
                        delete performerInfo.lastName;
                        createPerformer = { ...createPerformer, ...performerInfo };
                        delete performerInfo.ipn;
                        createPerfClient = { ...createPerfClient, ...performerInfo };

                        dispatch(
                            createPerformerClient(performersModel.selectedIndex, history.push, {
                                createPerformer,
                                createPerfClient,
                            }),
                        );
                    }
                    dispatch(changeStateByNestedKey('performers', 'spotifyIdErrorText', ''));
                    dispatch(changeStateByNestedKey('performers', 'aliasesPerformerErrorText', ''));
                }
            }
        }
    };

    const handleCancel = () => {
        if (isInSaveNewMode) setGoToPerformersPage(true);
        dispatch(changeStateByNestedKey('performers', 'isInEditMode', false));
        dispatch(changeStateByNestedKey('performers', 'isInSaveNewMode', false));
    };

    return (
        <div>
            <div className="flex-horizontal">
                {goToPerformersPage ? <Redirect to="/performers" /> : null}
                {(isInEditMode || isInSaveNewMode) && (
                    <Button
                        className={classes.btnInGroup}
                        // disabled={isDisabled}
                        color="primary"
                        variant="outlined"
                        disableElevation
                        onClick={handleCancel}
                    >
                        CANCEL
                    </Button>
                )}
                <Button
                    className={classes.btnInGroup}
                    // disabled={isDisabled}
                    color="primary"
                    variant="secondary"
                    onClick={handleNewClientSave}
                    disableElevation
                >
                    {!(isInEditMode || isInSaveNewMode) ? 'EDIT' : 'SAVE'}
                </Button>
                {!isInSaveNewMode && isDeletable && (
                    <DeleteOutline onClick={() => setIsDialogOpen(true)} className={classes.btn} />
                )}
            </div>
            <DeletePerformerDialog
                isOpen={isDialogOpen}
                closeCb={setIsDialogOpen}
                clientId={match.params.id}
            />
        </div>
    );
}

const withUrlPerformerEditButtonGroup = withRouter(PerformerEditButtonGroup);
export default connect((r) => r)(withUrlPerformerEditButtonGroup);
