import React from 'react';

import { downloadExportFileAPI } from '@api/asyncEventsController';
import { chipThemes } from '@common/CommonDropDownChips';
import { renderExportChip } from '@common/customChips';
import OverflowTip from '@common/OverflowTip';
import InfoIconSmallBlueSvg from '@images/svgComponents/InfoIconSmallBlueSvg';
import WarningOrangeSvg from '@images/svgComponents/WarningOrangeSvg';
import { ErrorOutline } from '@mui/icons-material';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadSharpIcon from '@mui/icons-material/UploadSharp';
import { CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';

// left for the warnings update
// const getAlertIconAndTooltip = (alertType) => {
//     let icon = null;
//     let tooltipTitle = '';
//
//     switch (alertType) {
//         case 'DISPUTE':
//             icon = <ErrorOutline sx={{ color: '#F44139', width: 18, height: 18 }} />;
//             tooltipTitle = 'Internal action required';
//             break;
//         case 'EVIDENCE_REQUIRED':
//         case 'CMO_VALIDATION':
//             icon = <WarningOrangeSvg style={{ width: '18px', height: '18px' }} />;
//             tooltipTitle =
//                 alertType === 'EVIDENCE_REQUIRED' ? 'Client action required' : 'Validation error';
//             break;
//         case 'ACTION_REQUIRED':
//             icon = <InfoIconSmallBlueSvg style={{ width: '18px', height: '18px' }} />;
//             tooltipTitle = 'Awaiting CMO action';
//             break;
//         default:
//             break;
//     }
//
//     return { icon, tooltipTitle };
// };

const EventsTableRow = ({ headerItem, dataItem }) => {
    const columnTitle = headerItem.title.toLowerCase();

    const downloadExportFile = async () => {
        await downloadExportFileAPI(dataItem.fileId);
    };

    if (columnTitle.includes('description')) {
        return (
            <Typography variant="body2" component="span" noWrap>
                <strong>{dataItem.eventSummary?.numberOfProcessedAssets}</strong> tracks to{' '}
                <strong>{dataItem.exportType}</strong> for{' '}
                <strong>
                    {dataItem.eventSettings.exportSettings.cmoName || dataItem.eventSettings.cmoId}
                </strong>
            </Typography>
        );
    } else if (columnTitle === 'event') {
        const isExport = dataItem[headerItem.dataIndex] === 'EXPORT';
        const IconComponent = isExport ? DownloadForOfflineOutlinedIcon : UploadSharpIcon;

        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <IconComponent fontSize="medium" />
                <Typography variant="body2" component="span" noWrap sx={{ marginLeft: '9px' }}>
                    {dataItem[headerItem.dataIndex]}
                </Typography>
            </span>
        );
    } else if (columnTitle === 'date') {
        return (
            <Typography variant="body2" component="span" noWrap>
                {format(parseISO(dataItem[headerItem.dataIndex]), 'dd/MM/yyyy HH:mm')}
            </Typography>
        );
    } else if (headerItem.dataIndex === 'eventStatus') {
        return renderExportChip(dataItem[headerItem.dataIndex]);
    } else if (columnTitle === '') {
        const status = dataItem.eventStatus;

        switch (status) {
            case 'IN_PROGRESS':
                return (
                    <IconButton>
                        <CircularProgress
                            size="20px"
                            sx={{
                                color: '#666',
                            }}
                        />
                    </IconButton>
                );
            case 'SUCCESS':
                return (
                    <IconButton onClick={downloadExportFile}>
                        <GetAppOutlinedIcon size="20px" />
                    </IconButton>
                );
            default:
                return (
                    <Tooltip title={dataItem.errorCode || 'error'}>
                        <IconButton>
                            <InfoOutlinedIcon size="20px" />
                        </IconButton>
                    </Tooltip>
                );
        }
    } else {
        return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
    }
};

export default EventsTableRow;
