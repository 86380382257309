import { changeStateByNestedKey } from '@actions/fugaActions';
import Fetch from '@utils/Fetch';

function constructURL(baseUrl, path, queryParams) {
    const url = new URL(`${baseUrl}/${path}`);
    url.search = new URLSearchParams(queryParams);
    return url;
}

function setupRequestOptions(bodyParams, method = 'POST') {
    return {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyParams),
    };
}

async function handlePostRequest({
    baseUrl,
    path,
    queryParams,
    bodyParams,
    successMessage = 'Operation successful',
    errorMessage = 'Operation failed',
    pendingMessage,
    withToasts = true,
}) {
    if (!queryParams.claimMethod) queryParams.claimMethod = 'AGENT';

    if (bodyParams.isrcs) {
        bodyParams = {
            ...bodyParams,
            isrcs: bodyParams.isrcs.split(',').map((item) => item.trim()),
        };
    }

    const url = constructURL(baseUrl, path, queryParams);
    const options = setupRequestOptions(bodyParams);

    return withToasts
        ? Fetch.fetchWithToasts({
              url,
              options,
              successMessage,
              errorMessage,
              pendingMessage,
          })
        : Fetch.fetchWithErrorToast({
              url: url.toString(),
              options,
          });
}

const fetchData = async (url, dispatch, stateType, options = {}) => {
    try {
        const res = await Fetch.fetchWithErrorToast({
            url,
            options,
            errorMessage: 'An error has occurred while fetching data',
        });
        await handleFetchTableResponse(res, dispatch, stateType);
    } catch (error) {
        console.log('Failed to send request via url: ' + url, `error msg: ${error}`);
    }
};

const handleFetchTableResponse = async (res, dispatch, stateType) => {
    const dataToSet = res.data.content ? res.data : { ...res.data, content: [] };
    dispatch(changeStateByNestedKey(stateType, 'dataFromBE', dataToSet));
    dispatch(changeStateByNestedKey(stateType, 'tableIsLoading', false));
};

export { handlePostRequest, fetchData };
