import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseAssetUrl();

async function exportTemplate(
    cmoId,
    queryParams = {
        needExport: false,
        claimMethod: '',
    },
    bodyParams = {},
    isPerformer,
    url,
    messages = {
        successMessage: 'Async export launched successfully, check the status in the events page',
        errorMessage: 'Async export launch failed',
        pendingMessage: 'Launching async export, please wait...',
    },
) {
    let search = {};

    if (Object.hasOwn(queryParams, 'needExport')) {
        search.needExport = queryParams.needExport;
    }

    if (queryParams.claimMethod) {
        search.claimMethod = queryParams.claimMethod;
    }

    url.search = new URLSearchParams(search);

    if (bodyParams.downloadAll) {
        delete bodyParams.includeAssetIds;
    } else {
        delete bodyParams.excludeAssetIds;
    }

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyParams),
    };

    await Fetch.fetchWithToasts({
        url,
        options,
        successMessage: messages.successMessage,
        errorMessage: messages.errorMessage,
        pendingMessage: messages.pendingMessage,
    });
}

function exportToGenericXLS(
    cmoId,
    queryParams = {
        needExport: false,
    },
    bodyParams = {},
    isPerformer,
) {
    const isPerformerStr = isPerformer ? 'performer' : 'export/rh';
    const url = new URL(`${CONTROLLER_URL}/export/${isPerformerStr}/generic/${cmoId}`);
    const messages = {
        successMessage: 'Generic XLS async export started successfully, please check notifications',
        errorMessage: 'Generic XLS export failed',
        pendingMessage: 'Exporting Generic XLS, please wait...',
    };
    return exportTemplate(cmoId, queryParams, bodyParams, isPerformer, url, messages);
}

function exportToMatchingTool(
    cmoId,
    queryParams = {
        needExport: false,
        claimMethod: '',
    },
    bodyParams = {},
    isPerformer,
) {
    const isPerformerStr = isPerformer ? 'performer' : 'rh';
    const url = new URL(`${CONTROLLER_URL}/export/${isPerformerStr}/matching-tool/cmo/${cmoId}`);
    const messages = {
        successMessage:
            'Matching tool async export started successfully, please check notifications',
        errorMessage: 'Matching tool export failed',
        pendingMessage: 'Exporting to matching tool, please wait...',
    };
    return exportTemplate(cmoId, queryParams, bodyParams, isPerformer, url, messages);
}

function exportToCmoTemplate(
    cmoId,
    queryParams = {
        needExport: false,
        claimMethod: '',
    },
    bodyParams = {},
    isPerformer,
) {
    let url;
    if (isPerformer) {
        url = new URL(`${CONTROLLER_URL}/export/performer/cmo/v2/${cmoId}`);
    } else if (parseInt(cmoId) === 3) {
        url = new URL(`${CONTROLLER_URL}/export/xml/cmo/${cmoId}`);
    } else {
        url = new URL(`${CONTROLLER_URL}/export/rh/cmo/v2/${cmoId}`);
    }
    const messages = {
        successMessage:
            'CMO template async export started successfully, please check notifications',
        errorMessage: 'CMO template export failed',
        pendingMessage: 'Exporting CMO template, please wait...',
    };
    return exportTemplate(cmoId, queryParams, bodyParams, isPerformer, url, messages);
}

function exportSpecificRepertoireAPI(clientId) {
    const url = `${CONTROLLER_URL}/export/performer/specific/${clientId}`;

    return Fetch.fetchWithToasts({
        url,
        downloadResp: true,
        successMessage: 'Export completed successfully',
        errorMessage: 'Export failed',
        pendingMessage: 'Exporting data, please wait...',
    });
}

function exportAllRepertoireRhAndPerformerAPI(isPerformer) {
    const urlPerfOrHR = isPerformer ? 'performer' : 'rh';
    const url = `${CONTROLLER_URL}/export/${urlPerfOrHR}/repertoire/all`;

    return Fetch.fetchWithErrorToast({
        url,
        downloadResp: true,
        successMessage: 'Export completed successfully',
        errorMessage: 'Export failed',
        pendingMessage: 'Exporting data, please wait...',
    });
}

export {
    exportToGenericXLS,
    exportToCmoTemplate,
    exportToMatchingTool,
    exportSpecificRepertoireAPI,
    exportAllRepertoireRhAndPerformerAPI,
};
