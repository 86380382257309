import React, { useCallback, useEffect, useState } from 'react';

import { changeStateByNestedKey, getAllUsers } from '@actions/fugaActions';
import { deleteContactByIdAPI, getContactByIdAPI } from '@api/contactController';
import { exportStatementsUserInfoAPI, getPeriodsForStatementsAPI } from '@api/statementsController';
import { BreadcrumbNavigation } from '@common/BreadcrumbNavigation';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import CommonDropDownWithCheckboxesBtn from '@common/CommonDropDownWithCheckboxesBtn';
import CommonMoreDropDownButton from '@common/CommonMoreDropDownButton';
import CommonTableGrid from '@common/CommonTable/CommonTableGrid';
import ContactDetailsModal from '@common/modals/ContactDetailsModal';
import OverflowTip from '@common/OverflowTip';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { APP_ROUTES, BREADCRUMB_TEXTS } from '@utils/navigation';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    mainComponent: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
    },
    flexLeft: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    flexRight: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: '16px',
    },
    addBtn: {
        marginLeft: '8px',
    },
    plusIcon: {
        margin: '0 7px 0 0',
        width: '18px',
        height: '18px',
    },
}));

function UserManagementPage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const usersModel = useSelector((state) => state.fugaReducers.users);
    const contactForEditModel = useSelector((state) => state.fugaReducers.users.contactForEdit);
    const selectedRowDataItemModel = useSelector(
        (state) => state.fugaReducers.users.selectedRowDataItem,
    );
    const [openCreateContactModal, setOpenCreateContactModal] = useState(false);
    const [isModalEditing, setIsModalEditing] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [deleteOptions, setDeleteOptions] = useState({});
    const periods = useSelector((state) => state.fugaReducers.periods.dataFromBE);

    const [menuItemsList, setMenuItemsList] = useState([]);

    const getPeriodsForStatements = async () => {
        const resp = await getPeriodsForStatementsAPI();
        await dispatch(changeStateByNestedKey('periods', 'dataFromBE', resp.data));
    };

    useEffect(() => {
        getPeriodsForStatements();
    }, []);

    useEffect(() => {
        if (periods?.length && periods?.length > 0) {
            const updatedMenuItemsList = [
                {
                    title: 'ALL',
                },
                ...periods.map((period) => ({
                    title: period,
                })),
            ];

            setMenuItemsList(updatedMenuItemsList);
        }
    }, [periods]);

    useEffect(() => {
        dispatch(getAllUsers());
    }, []);

    const handleAddContactClick = () => {
        setOpenCreateContactModal(true);
    };

    const handleExportStatementsUserInfo = async (selectedItems) => {
        await exportStatementsUserInfoAPI(selectedItems);
    };

    const deleteContact = async () => {
        await deleteContactByIdAPI(deleteOptions.contactId);
        dispatch(getAllUsers());
    };

    const headers = [
        {
            title: 'FUGA ID',
            dataIndex: 'fugaId',
        },
        {
            title: 'Email address',
            dataIndex: 'email',
        },
        {
            title: 'First name',
            dataIndex: 'firstname',
        },
        {
            title: 'Surname',
            dataIndex: 'lastname',
        },
        {
            title: 'Role',
            dataIndex: 'role',
        },
        {
            title: 'NRP access',
            dataIndex: 'nrpAcess',
        },
        {
            title: 'Client(s)',
            dataIndex: 'clients',
        },
        {
            title: 'Last login',
            dataIndex: 'lastLoginDate',
        },
        {
            title: '',
            dataIndex: '',
            hasNumericData: true,
        },
    ];

    const moreOptionsSpecificData = useCallback(
        (headerItem, dataItem) => {
            const columnTitle = headerItem.title.toLowerCase();
            if (columnTitle === '') {
                const menuItems = [
                    {
                        title: 'Edit',
                        onClickClb: async () => {
                            setIsModalEditing(true);
                            dispatch(
                                changeStateByNestedKey('users', 'selectedRowDataItem', dataItem),
                            );
                            const resp = await getContactByIdAPI(dataItem.contactId);
                            dispatch(changeStateByNestedKey('users', 'contactForEdit', resp?.data));
                            setOpenCreateContactModal(true);
                        },
                    },
                    {
                        title: 'Delete',
                        onClickClb: () => {
                            setIsDialogOpen(true);
                            setDeleteOptions({ contactId: dataItem.contactId });
                        },
                    },
                ];
                return <CommonMoreDropDownButton menuItems={menuItems} />;
            } else {
                return <OverflowTip>{dataItem[headerItem.dataIndex]}</OverflowTip>;
            }
        },
        [usersModel.dataFromBE],
    );

    return (
        <div className={classes.mainComponent}>
            <BreadcrumbNavigation
                items={[
                    {
                        href: APP_ROUTES.USERS,
                        text: BREADCRUMB_TEXTS.USER_MANAGEMENT,
                        disabled: true,
                    },
                    { href: APP_ROUTES.USERS, text: BREADCRUMB_TEXTS.USERS },
                ]}
                pageName="Users"
            />
            <div className={classes.flexRight}>
                <CommonDropDownWithCheckboxesBtn
                    handleApplyCB={handleExportStatementsUserInfo}
                    btnTitle="contacts export"
                    menuItemsList={menuItemsList}
                    customStyles={{ color: 'primary', variant: 'outlined' }}
                />
                <Button
                    variant="secondary"
                    className={classes.addBtn}
                    disableElevation
                    disableRipple
                    onClick={handleAddContactClick}
                >
                    <AddIcon className={classes.plusIcon} />
                    ADD CONTACT
                </Button>
            </div>
            <CommonTableGrid
                dataList={usersModel.dataFromBE}
                headerList={headers}
                loading={usersModel.tableIsLoading}
                cellDataCb={moreOptionsSpecificData}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: getAllUsers,
                        argumentsList: [],
                    },
                }}
                sortPrefs={{
                    storeName: 'users',
                    storeSubName: 'sortValue',
                    value: usersModel.sortValue,
                    sortOnly: true,
                }}
                paginationBE={true}
                initialRowsPerPage={1000}
                selection={{
                    isSelectable: false,
                }}
            />
            {/* uncomment when tabs (USERS, REPCOS) will be implemented  */}
            {/* <UserInfoNavigation /> */}
            <ContactDetailsModal
                isEditing={isModalEditing}
                setIsEditing={setIsModalEditing}
                isOpen={openCreateContactModal}
                setOpen={setOpenCreateContactModal}
                modalData={contactForEditModel}
                dataItem={selectedRowDataItemModel}
            />
            <CommonConfirmationDialog
                isOpen={isDialogOpen}
                closeCb={setIsDialogOpen}
                title="Delete Contact?"
                description="Are you sure you want to delete this contact including any NRP user permissions?"
                onConfirmCb={deleteContact}
            />
        </div>
    );
}

export default UserManagementPage;
