import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.basePerformerUrl();

function getPerformersListAPI({ searchString, signal, filterClientConnected = true }) {
    const url = new URL(`${CONTROLLER_URL}/all`);

    url.search = new URLSearchParams({
        ...(filterClientConnected ? { filterClientConnected } : {}),
        ...(searchString ? { performerName: searchString } : {}),
    });

    return Fetch.fetchWithErrorToast({
        url,
        signal,
        errorMessage: 'Failed to get performers list',
    });
}

function createPerformerClientAPI(content) {
    const url = `${CONTROLLER_URL}/create-performer`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            ...content.createPerformer,
        }),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Performer was created successfully',
        errorMessage: 'Failed to create performer',
        pendingMessage: 'Creating performer...',
    });
}

export { getPerformersListAPI, createPerformerClientAPI };
