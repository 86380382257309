import React, { useState } from 'react';

import { ArrowDropDown } from '@mui/icons-material';
import { Button as MuiButton, Menu, MenuItem, Typography, styled } from '@mui/material';

const MenuItemStyled = styled(MenuItem)({
    padding: '0px 16px',
    height: '48px',
});

const IconWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
});

const ButtonWrapper = styled(MuiButton)({
    display: 'flex',
    alignItems: 'center',
});

export default function CommonDropDownButtonWithFixedLabel({
    btnTitle,
    manualAlertsItemsList,
    customStyles,
    selectedValue,
    setSelectedValue,
}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedValue(null);
    };

    const onItemClick = (item) => {
        handleClose();
        setSelectedValue(item);
        if (item.onClickClb) item.onClickClb();
    };

    return selectedValue ? (
        <div style={{ display: 'flex' }}>
            <IconWrapper color={selectedValue.color}>{selectedValue?.coloredIcon()}</IconWrapper>
            <Typography variant="subtitle2" style={{ color: selectedValue.color }}>
                {selectedValue.title}
            </Typography>
        </div>
    ) : (
        <>
            <ButtonWrapper
                variant={customStyles?.variant || 'light'}
                onClick={handleOpen}
                disableElevation
                disableRipple
                sx={{
                    color: customStyles?.color || '#000',
                    textTransform: 'none',
                    letterSpacing: '0.5px',
                    paddingRight: '8px',
                    paddingLeft: '8px',
                }}
            >
                {btnTitle}
                <ArrowDropDown sx={{ marginLeft: '16px', color: 'inherit' }} />
            </ButtonWrapper>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {manualAlertsItemsList.map((item, ind) => (
                    <MenuItemStyled key={`${ind}-${item.title}`} onClick={() => onItemClick(item)}>
                        <IconWrapper color={item.color}>{item?.coloredIcon()}</IconWrapper>
                        <Typography variant="body1" style={{ color: item.color }}>
                            {item.title}
                        </Typography>
                    </MenuItemStyled>
                ))}
            </Menu>
        </>
    );
}
