import React, { useState, useEffect, useRef } from 'react';

import { Tooltip } from '@mui/material';

const OverflowTip = ({ children }) => {
    const [isOverflowed, setIsOverflow] = useState(false);
    const textElementRef = useRef();

    useEffect(() => {
        if (textElementRef.current) {
            setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
        }
    }, []);

    return (
        <Tooltip title={children} disableHoverListener={!isOverflowed}>
            <span
                ref={textElementRef}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'inline-block',
                    verticalAlign: 'top',
                }}
            >
                {children}
            </span>
        </Tooltip>
    );
};

export default OverflowTip;
