import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseAsyncEventsUrl();

const getAsyncEvents = async (queryParams, requestBody) => {
    try {
        const url = new URL(`${CONTROLLER_URL}`);

        url.search = new URLSearchParams({
            ...(queryParams.page ? { page: queryParams.page } : {}),
            ...(queryParams.size ? { size: queryParams.size } : {}),
            ...(queryParams.sort ? { sort: queryParams.sort } : {}),
        });

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        };

        return Fetch.fetchWithErrorToast({
            url,
            options,
            errorMessage: 'Failed to get async events',
        });
    } catch (e) {
        return [];
    }
};

const downloadExportFileAPI = (fileId) => {
    try {
        const url = new URL(`${CONTROLLER_URL}/export/file/${fileId}`);

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Cache-Control': 'no-store',
            },
        };

        return Fetch.fetchWithToasts({
            url,
            options,
            downloadResp: true,
            successMessage: 'Export file downloaded successfully',
            pendingMessage: 'Downloading export file...',
            errorMessage: 'Failed to download export file',
        });
    } catch (e) {
        console.error('Failed to download export file', e);
    }
};

export { getAsyncEvents, downloadExportFileAPI };
