import React, { useEffect } from 'react';

import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';

const DatesAndClaimMethods = ({
    isEditing,
    claimMethod,
    setClaimMethod,
    selectedStartDate,
    handleStartDateChange,
    selectedEndDate,
    handleEndDateChange,
    setShowRenewalUpdate,
    disableExclusiveLicense,
    initialStartDate,
}) => {
    useEffect(() => {
        if (!isEditing) {
            handleStartDateChange(initialStartDate || null);
            handleEndDateChange(null);
        }
    }, []);

    const handleRadioChange = (event) => {
        setClaimMethod(event.target.value);
    };

    const handleEndDateFocus = () => {
        if (isEditing) setShowRenewalUpdate(true);
    };
    return (
        <>
            <Typography variant="subtitle1" style={{ marginBottom: '21px' }}>
                Dates & claim methods
            </Typography>
            <Typography variant="body2" style={{ marginBottom: '8.5px' }}>
                Claim methods
            </Typography>
            <RadioGroup
                value={claimMethod}
                onChange={handleRadioChange}
                sx={{
                    '& .MuiSvgIcon-root': {
                        color: '#fff',
                    },
                }}
            >
                <FormControlLabel
                    value="agent"
                    control={<Radio size="small" color="primary" disabled={isEditing} />}
                    label={<Typography variant="body2">Agent</Typography>}
                />
                <FormControlLabel
                    value="exclusive_license"
                    control={
                        <Radio
                            size="small"
                            color="primary"
                            disabled={isEditing || disableExclusiveLicense}
                        />
                    }
                    label={<Typography variant="body2">Exclusive license</Typography>}
                />
            </RadioGroup>
            <Grid container spacing={4} style={{ marginTop: '.5px' }}>
                <Grid item>
                    <DatePicker
                        label="Start Date"
                        value={selectedStartDate}
                        onChange={(date) => handleStartDateChange(date)}
                        format="dd/MM/yyyy"
                        disabled={isEditing}
                        renderInput={(params) => <TextFieldWrapper {...params} />}
                    />
                </Grid>
                <Grid item>
                    <DatePicker
                        label="End Date"
                        value={selectedEndDate}
                        onChange={(date) => handleEndDateChange(date)}
                        format="dd/MM/yyyy"
                        onFocus={handleEndDateFocus}
                        renderInput={(params) => <TextFieldWrapper {...params} />}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default DatesAndClaimMethods;
