/**
 * To use environment variables:
 *      * create .env file in the root folder(next to src/ and public/ folders)
 *      * write variables with their values in that file (e.g REACT_APP_BASE_URL=http://localhost:8080/api/v1)
 *      * make sure that 'react-dotenv' package is installed
 */

const userData = {
    api: {
        // baseUrl: window._env_.REACT_APP_BASE_URL,
        baseUrl: import.meta.env.REACT_APP_BASE_URL,
        baseLocalServerUrl: 'http://localhost:3001',
        // Lines bollow are used for development purpose - this needs a example for what and where it's used.
        baseCerberusUrl: import.meta.env.REACT_APP_CERBERUS_API_URL,
        // baseCerberusUrl: window._env_.REACT_APP_CERBERUS_API_URL,
        baseAPIServerUrl: import.meta.env.REACT_APP_BASE_WRAPPER_API_URL,
        // baseAPIServerUrl: window._env_.REACT_APP_BASE_WRAPPER_API_URL,
        // **************************************************
        baseCmoUrl: function () {
            return this.baseUrl + '/cmo';
        },
        baseCommentUrl: function () {
            return this.baseUrl + '/comment';
        },
        baseAlertUrl: function () {
            return this.baseUrl + '/alert';
        },
        baseCmoReportUrl: function () {
            return this.baseUrl + '/cmo-report';
        },
        baseClientUrl: function () {
            return this.baseUrl + '/client';
        },
        baseClientDealUrl: function () {
            return this.baseUrl + '/client-deal';
        },
        baseAssetUrl: function () {
            return this.baseUrl + '/asset';
        },
        baseAsyncEventsUrl: function () {
            return this.baseUrl + '/async/event';
        },
        baseAssetLocalUrl: function () {
            return this.baseLocalServerUrl + '/asset';
        },
        baseRHUrl: function () {
            return this.baseUrl + '/rh';
        },
        baseTerritoriesUrl: function () {
            return this.baseUrl + '/territories';
        },
        baseLocalAssetUrl: function () {
            return this.baseLocalServerUrl + '/asset';
        },
        basePerformersUrl: function () {
            return this.baseUrl + '/performers';
        },
        basePerformerUrl: function () {
            return this.baseUrl + '/performer';
        },
        basePerformerClientUrl: function () {
            return this.baseUrl + '/performer-client';
        },
        baseStatementsUrl: function () {
            return this.baseUrl + '/statements';
        },
        baseUsersUrl: function () {
            return this.baseUrl + '/users';
        },
        baseFileStorageUrl: function () {
            return this.baseUrl + '/file-storage';
        },
        baseNotificationUrl: function () {
            return this.baseUrl + '/notification';
        },
        baseContactUrl: function () {
            return this.baseUrl + '/contact';
        },
        baseMetadatatUrl: function () {
            return this.baseUrl + '/metadata';
        },
        baseAssetClaimEventUrl: function () {
            return this.baseUrl + '/asset-claim-event';
        },
        baseWrapperAPIUrl: function () {
            return this.baseAPIServerUrl;
        },
        baseCerberusAPIUrl: function () {
            return this.baseCerberusUrl;
        },
    },
};

export default userData;
