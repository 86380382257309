import React from 'react';

import { chipThemes } from '@common/CommonDropDownChips';
import { Chip, styled } from '@mui/material';

export const ChipLabel = styled(Chip)(({ theme }) => ({
    height: '26px',
    borderRadius: '4px',
    borderWidth: '1px',
    padding: '4px 8px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '14px',
    textAlign: 'center',
    letterSpacing: '1.5px',
    justifyContent: 'normal',
    '&.Mui-disabled': {
        backgroundColor: '#ececee',
    },
    '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.07) !important',
    },
    '&:focus': {
        backgroundColor: 'rgba(0,0,0,0.12) !important',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: 'rgba(0,0,0,0.12)',
    },
}));

export const renderExportChip = (status) => {
    let chipStyle;
    let statusText;

    switch (status) {
        case 'IN_PROGRESS':
            chipStyle = chipThemes.blueChip;
            statusText = 'export in progress';
            break;
        case 'SUCCESS':
            chipStyle = chipThemes.greenChip;
            statusText = 'export completed';
            break;
        case 'FAILED':
            chipStyle = chipThemes.redChip;
            statusText = 'export failed';
            break;
        default:
            chipStyle = {};
            statusText = status;
    }

    return <ChipLabel label={statusText.toUpperCase()} style={{ ...chipStyle, fontWeight: 700 }} />;
};

export const renderIngestionChip = (status) => {
    let chipStyle;
    let statusText;

    switch (status) {
        case 'IN_PROGRESS':
            chipStyle = chipThemes.blueChip;
            statusText = 'ingestion in progress';
            break;
        case 'COMPLETED':
            chipStyle = chipThemes.greenChip;
            statusText = 'ingestion completed';
            break;
        case 'SERVER_ERROR':
        case 'FAILED':
            chipStyle = chipThemes.redChip;
            statusText = 'ingestion failed';
            break;
        default:
            chipStyle = {};
            statusText = status;
    }

    return <ChipLabel label={statusText.toUpperCase()} style={{ ...chipStyle, fontWeight: 700 }} />;
};
