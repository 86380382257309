import React, { useState, useEffect } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { PERMISSIONS_REPERTOIRE, PERMISSIONS_STATEMENTS } from '@data/globalConstants';
import { FormControl, Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import ClientExternalUserAccessContent from '@pages/ClientPage/ClientExternalUserAccessContent';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const useClasses = makeStyles(() => ({
    disabledInfoIcon: {
        cursor: 'default !important',
        '& > svg': {
            fill: 'rgba(0, 0, 0, 0.26)',
        },
    },
    checkboxHelpIconContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    helpIcon: {
        width: '20px',
        height: '20px',
        marginLeft: '8px',
        cursor: 'pointer',
    },
    infoPopoverRows: {
        paddingBottom: '8px',
        paddingTop: '2px',
    },
    infoPopoverContainer: {
        '& .MuiPopover-paper': {
            width: '444px',
            padding: '16px 16px',
            boxSizing: 'content-box',
            marginTop: '7px',
        },
    },
}));

const PerformerContactsAndUsersContent = () => {
    const dispatch = useDispatch();
    const classes = useClasses();
    const params = useParams();
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const { byClietnId } = performersModel;
    const [isDisabled, setIsDisabled] = useState(true);
    const [clientRepertorireCheckbox, setClientRepertorireCheckbox] = useState(false);
    const [clientStatementsCheckbox, setClientStatementsCheckbox] = useState(false);

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        if (params.id === '-1') {
            setIsDisabled(false);
        }
    }, []);

    useEffect(() => {
        const accuratePermValue =
            params.id === '-1' ? [PERMISSIONS_STATEMENTS] : byClietnId.permissions || [];
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                clientPermissions: accuratePermValue,
            }),
        );
    }, [performersModel.byClietnId]);

    const setIitialValueForPermissionsCheckboxes = () => {
        for (const [checkboxName, valueFromLocalSelected] of Object.entries(permissionsMaping)) {
            const foundValueFromBE =
                performersModel?.mainDetailsInptValues?.clientPermissions?.find(
                    (itemFomBE) => itemFomBE === valueFromLocalSelected,
                );
            permissionsValueSetterMapping[checkboxName](!!foundValueFromBE);
        }
    };

    useEffect(() => {
        setIitialValueForPermissionsCheckboxes();
    }, [byClietnId.permissions]);

    const permissionsMaping = {
        clientRepertorireCheckbox: PERMISSIONS_REPERTOIRE,
        clientStatementsCheckbox: PERMISSIONS_STATEMENTS,
    };

    const permissionsValueSetterMapping = {
        clientStatementsCheckbox: setClientStatementsCheckbox,
        clientRepertorireCheckbox: setClientRepertorireCheckbox,
    };

    const handleContactsAndUsersPermissions = (event, key) => {
        const checkboxName = event?.target?.name;
        const value = permissionsMaping[checkboxName];
        const permissionsSet = new Set(performersModel.mainDetailsInptValues[key]);
        if (event?.target?.checked) {
            permissionsSet.add(value);
            permissionsValueSetterMapping[checkboxName](true);
        } else {
            permissionsSet.delete(value);
            permissionsValueSetterMapping[checkboxName](false);
        }
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [key]: Array.from(permissionsSet),
            }),
        );
    };

    return (
        <>
            <div style={{ marginBottom: '32px' }}>
                <Grid spacing={4} container>
                    <Grid
                        size={{
                            xs: 6,
                            md: 6,
                        }}
                    >
                        <ClientExternalUserAccessContent />
                        <FormControl
                            variant="standard"
                            className={classes.checkboxHelpIconContainer}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={isDisabled}
                                        onChange={(e) =>
                                            handleContactsAndUsersPermissions(
                                                e,
                                                'clientPermissions',
                                            )
                                        }
                                        checked={!!clientRepertorireCheckbox}
                                        color="primary"
                                        name="clientRepertorireCheckbox"
                                        tabIndex="6"
                                    />
                                }
                                label="Repertoire"
                                color="primary"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={isDisabled}
                                        onChange={(e) =>
                                            handleContactsAndUsersPermissions(
                                                e,
                                                'clientPermissions',
                                            )
                                        }
                                        checked={!!clientStatementsCheckbox}
                                        color="primary"
                                        name="clientStatementsCheckbox"
                                        tabIndex="7"
                                    />
                                }
                                label="Statements"
                                color="primary"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default PerformerContactsAndUsersContent;
