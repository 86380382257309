import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseNotificationUrl();

const getNotificationsAPI = () => {
    const url = `${CONTROLLER_URL}`;

    const options = {
        method: 'POST',
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        errorMessage: 'Failed to get notifications',
    });
};

export { getNotificationsAPI };
