import { changeStateByNestedKeyNew } from '@actions/fugaActions';
import { getAsyncEvents } from '@api/asyncEventsController';

const getEventsData = (queryParams, requestBody) => {
    return async (dispatch) => {
        dispatch(changeStateByNestedKeyNew('eventsPage', 'tableIsLoading', true));

        const res = await getAsyncEvents(queryParams, requestBody);

        dispatch(changeStateByNestedKeyNew('eventsPage', 'dataFromBE', res?.data));
        dispatch(changeStateByNestedKeyNew('eventsPage', 'tableIsLoading', false));
    };
};

export { getEventsData };
