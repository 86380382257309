import React, { useState } from 'react';

import { Popover, MenuItem, Typography, Box } from '@mui/material';

export default function CommonIconDropDownButton({ triggerButton, menuItemsList }) {
    const [anchorPosition, setAnchorPosition] = useState(null);

    const handleOpen = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setAnchorPosition({
            top: 60,
            left: rect.left + window.scrollX,
        });
    };

    const handleClose = () => {
        setAnchorPosition(null);
    };

    const onItemClick = (item) => {
        handleClose();
        if (item.onClickClb) item.onClickClb();
    };

    return (
        <>
            <Box onClick={handleOpen} sx={{ display: 'inline-flex' }}>
                {triggerButton}
            </Box>

            <Popover
                open={Boolean(anchorPosition)}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition || { top: 60, left: 0 }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', paddingY: 1 }}>
                    {menuItemsList &&
                        menuItemsList.map((item, index) => (
                            <MenuItem
                                key={`${index}-${item.title}`}
                                onClick={() => onItemClick(item)}
                                sx={{
                                    padding: '8px 16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                {item.icon ? item.icon : null}
                                <Typography variant="body1">{item.title}</Typography>
                            </MenuItem>
                        ))}
                </Box>
            </Popover>
        </>
    );
}
