import React, { useCallback } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { getPerformersListAPI } from '@api/performerController';
import { useDispatch, useSelector } from 'react-redux';

import Lookup from './Lookup';

const PerformerLookup = () => {
    const dispatch = useDispatch();

    const open = useSelector((state) => state.fugaReducers.performers.isNewClientModalOpen);

    const suggestionSelector = (state) => state.fugaReducers.performers.suggestedClients;
    const dataSelector = (state) => state.fugaReducers.performers;

    const onClose = () =>
        dispatch(changeStateByNestedKey('performers', 'isNewClientModalOpen', false));

    const loadData = useCallback(async (searchString = '', signal) => {
        const resp = await getPerformersListAPI({ searchString, signal });

        dispatch(changeStateByNestedKey('performers', 'suggestedClients', resp.data));
    }, []);

    const navigateTo = '/performer-page/-1';
    const newItemData = {
        firstName: 'New',
        lastName: 'Client',
        clientDeals: [{ client_deal_id: '' }],
    };

    const setIsInSaveNewModeAction = (value) =>
        changeStateByNestedKey('performers', 'isInSaveNewMode', value);
    const setSelectedIndexAction = (index) =>
        changeStateByNestedKey('performers', 'selectedIndex', index);
    const setDataAction = (data) =>
        changeStateByNestedKey('performers', 'byClietnId', {
            ...data,
            firstName: data.firstname || '',
            lastName: data.lastname || '',
            clientDeals: [{ client_deal_id: '' }],
        });

    return (
        <Lookup
            open={open}
            onClose={onClose}
            searchAction={loadData}
            suggestionSelector={suggestionSelector}
            dataSelector={dataSelector}
            navigateTo={navigateTo}
            newItemData={newItemData}
            itemName="fullname"
            setIsInSaveNewModeAction={setIsInSaveNewModeAction}
            setSelectedIndexAction={setSelectedIndexAction}
            setDataAction={setDataAction}
            anchorPosition={{ top: 255, left: window.innerWidth - 200 }}
        />
    );
};

export default PerformerLookup;
