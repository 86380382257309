import React, { useState } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import { renderExportChip, renderIngestionChip } from '@common/customChips';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
    CircularProgress,
    Icon,
    IconButton,
    ListItem,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useDispatch } from 'react-redux';

const NotificationStatus = {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    SERVER_ERROR: 'SERVER_ERROR',
};

const NotificationTypes = {
    INGESTION: 'INGESTION',
    EXPORT: 'EXPORT',
};

const NotificationRow = ({ notification }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleViewResults = (id) => {
        dispatch(changeStateByNestedKey('repertoires', 'reportId', id));
        dispatch(changeStateByNestedKey('repertoires', 'errorDialogIsOpen', true));
        closeMenu();
    };

    const handleDownloadResults = () => {
        console.log('Downloading results...');
        closeMenu();
    };

    const renderIcon = (type) => {
        return type === NotificationTypes.INGESTION ? (
            <Icon
                sx={{
                    fontSize: 24,
                    height: '100%',
                }}
            >
                database_upload
            </Icon>
        ) : (
            <ArrowCircleDownOutlinedIcon />
        );
    };

    const renderStatusIcon = (notification) => {
        switch (notification.status) {
            case NotificationStatus.SERVER_ERROR:
            case NotificationStatus.FAILED:
                if (notification.notificationType === NotificationTypes.INGESTION) {
                    return (
                        <IconButton onClick={() => handleViewResults(notification.reportId)}>
                            <InfoOutlinedIcon color="error" />
                        </IconButton>
                    );
                } else {
                    return (
                        <Tooltip
                            title={notification.errorText}
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <InfoOutlinedIcon color="error" />
                        </Tooltip>
                    );
                }
            case NotificationStatus.COMPLETED:
                return (
                    <>
                        <IconButton onClick={openMenu}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={closeMenu}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={() => handleViewResults(notification.reportId)}>
                                <RemoveRedEyeIcon sx={{ marginRight: 1 }} />
                                View Ingestion Results
                            </MenuItem>
                            <MenuItem onClick={handleDownloadResults} disabled>
                                <DownloadIcon sx={{ marginRight: 1 }} />
                                Download Results
                            </MenuItem>
                        </Menu>
                    </>
                );
            case NotificationStatus.IN_PROGRESS:
                return <CircularProgress size={20} />;
            default:
                return null;
        }
    };

    return (
        <ListItem
            key={notification.asyncEventId}
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
            }}
        >
            <Grid container sx={{ width: '100%' }}>
                <Grid size={1}>{renderIcon(notification.notificationType)}</Grid>
                <Grid size={11} mb={1}>
                    <Typography variant="body2">
                        {notification.notificationType === NotificationTypes.INGESTION ? (
                            <Typography variant="body2">
                                <span style={{ fontWeight: 'bold' }}>{notification.user}</span>{' '}
                                ingested{' '}
                                <span style={{ fontWeight: 'bold' }}>{notification.filename}</span>
                            </Typography>
                        ) : (
                            <Typography variant="body2">
                                <span style={{ fontWeight: 'bold' }}>{notification.user}</span>{' '}
                                exported{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {notification.processedAssetsNumber}
                                </span>{' '}
                                tracks to{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {notification.exportType.split('_').join(' ')}
                                </span>{' '}
                                for{' '}
                                <span style={{ fontWeight: 'bold' }}>{notification.cmoName}</span>
                            </Typography>
                        )}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {new Date(notification.date).toLocaleString()}
                    </Typography>
                </Grid>
                <Grid size={1}></Grid>
                <Grid
                    size={10}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {notification.notificationType === NotificationTypes.INGESTION
                        ? renderIngestionChip(notification.status)
                        : renderExportChip(notification.status)}
                </Grid>
                <Grid
                    size={1}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    {renderStatusIcon(notification)}
                </Grid>
            </Grid>
        </ListItem>
    );
};

export default NotificationRow;
