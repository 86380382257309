import React, { useEffect, useState } from 'react';

import { getNotificationsAPI } from '@api/notificationController';
import NotificationRow from '@common/modals/Notifications/NotificationsRow';
import WarningDialog from '@common/WarningDialog';
import { Notifications as NotificationIcon, CloseOutlined as CloseIcon } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
    Popover,
    List,
    Typography,
    Divider,
    IconButton,
    Link,
    CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';

const Notification = ({ open, onClose }) => {
    const errorDialogIsOpen = useSelector(
        (state) => state.fugaReducers.repertoires.errorDialogIsOpen,
    );
    const [notificationsData, setNotificationsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getNotifications = async () => {
        try {
            setLoading(true);
            const { data } = await getNotificationsAPI();
            setNotificationsData(data.notifications);
        } catch (error) {
            console.error('Failed to get notifications', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getNotifications();
    }, []);

    return (
        <>
            <Popover
                open={open}
                anchorReference="anchorPosition"
                onClose={onClose}
                anchorPosition={{ top: 60, left: window.innerWidth - 200 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Grid px={2} py={1}>
                    <Grid container>
                        <Grid size={9} sx={{ display: 'flex', alignItems: 'center' }}>
                            <NotificationIcon />
                            <Typography variant="h6" ml={2}>
                                Notifications
                            </Typography>
                        </Grid>
                        <Grid size={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton onClick={onClose} size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container mb={1}>
                        <Grid size={6}>
                            <Typography variant="p">Showing last 3 days</Typography>
                        </Grid>
                        <Grid size={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Link
                                underline="hover"
                                sx={{
                                    fontSize: '0.875rem',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textDecoration: 'underline',
                                    fontWeight: '700',
                                }}
                                href={'/events'}
                            >
                                See all events
                                <ArrowForwardIcon />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <List sx={{ width: '440px', maxHeight: '400px', overflow: 'auto' }}>
                    {loading ? (
                        <Grid container justifyContent="center" sx={{ padding: 2 }}>
                            <CircularProgress size={24} />
                        </Grid>
                    ) : notificationsData.length === 0 ? (
                        <Grid container justifyContent="center" sx={{ padding: 2 }}>
                            <Typography variant="body2">No notifications</Typography>
                        </Grid>
                    ) : (
                        notificationsData.map((notification) => (
                            <NotificationRow
                                key={notification.reportId}
                                notification={notification}
                            />
                        ))
                    )}
                </List>
            </Popover>
            {errorDialogIsOpen && (
                <WarningDialog style={{ zIndex: '-1' }} isOpen={errorDialogIsOpen} />
            )}
        </>
    );
};

export default Notification;
