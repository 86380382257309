import React, { useMemo, useState } from 'react';

import {
    changeBulkStatusForPerformer,
    changeBulkStatusForRightsHolder,
} from '@actions/assetsClaimEvent';
import { ChipLabel } from '@common/customChips';
import AssetsCmoManualAlertsBulkActionsModal from '@common/modals/AssetsCmoManualAlertsBulkActionsModal';
import { AssetsRegistrationCommentsAddBulkModal } from '@common/modals/AssetsRegistrationCommentsAddBulkModal';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { Button as MuiButton, Menu, MenuItem, styled } from '@mui/material';
import { getFiltersToSend } from '@pages/AssetsCmoPage/common/common';
import { useSelector } from 'react-redux';

import { ASSET_STATUSES_LIST } from './CommonDropDownChips';
import { BulkUpdatesModal } from './modals/BulkUpdatesModal';

// Styled components
const ArrowDropDownIcon = styled(ArrowDropDown)(({ theme }) => ({
    width: '18px',
    height: '18px',
    marginLeft: '8px',
}));

const ArrowRightIcon = styled(ArrowRight)(({ theme }) => ({
    width: '18px',
    height: '18px',
    marginLeft: '8px',
}));

const MenuItemContainer = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const generateModalBodyText = (data) => {
    let statusChangesMessage = '';
    if (data.statusChanges) {
        for (const [key, value] of Object.entries(data.statusChanges)) {
            const [fromStatus, toStatus] = key.slice(1, -1).split(',');
            statusChangesMessage += `<li>${value} tracks from ${fromStatus} to ${toStatus}</li>`;
        }
    }

    const skippedCount = data?.skippedAssetNumber;

    return `Please confirm the following status changes:
                <ul>
                        ${statusChangesMessage}
                        <li>${skippedCount} tracks will be skipped.</li>
                </ul>
        `;
};

export default function BulkActionsDropDownButton({ queryParams, isPerformer, getAssetData }) {
    const [anchorElBulk, setAnchorElBulk] = useState(null);
    const [statusesAnchor, setStatusesAnchor] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalBodyText, setModalBodyText] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isManualAlertsBulkActionsModalOpen, setIsManualAlertsBulkActionsModalOpen] =
        useState(false);
    const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);

    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    const handleOpenBulk = ({ currentTarget }) => {
        setAnchorElBulk(currentTarget);
    };

    const handleCloseBulk = () => {
        setAnchorElBulk(null);
    };

    const handleCloseStatusesMenu = () => {
        setStatusesAnchor(null);
    };

    const handleOpenStatusesMenu = ({ currentTarget }) => {
        setStatusesAnchor(currentTarget);
    };

    const handleOpenManualAlertsBulkActionsModal = () => {
        setAnchorElBulk(null);
        setIsManualAlertsBulkActionsModalOpen(true);
    };

    const handleOpenCommentsAddBulkActionsModal = () => {
        setAnchorElBulk(null);
        setIsCommentsModalOpen(true);
    };

    const handleModalActionClick = async () => {
        await changeBulkStatus(selectedItem);
        await fetchData();
        handleCloseStatusesMenu();
        handleCloseBulk();
        handleModalCancelClick();
    };

    const handleStatusClick = async (status) => {
        setSelectedItem(status);
        handleCloseStatusesMenu();
        handleCloseBulk();
        setIsModalOpen(true);
        const updateInfo = await changeBulkStatus(status, false);
        setModalBodyText(generateModalBodyText(updateInfo));
    };

    const menuItemsList = useMemo(() => {
        const assetStatusesSet = new Set(Object.keys(ASSET_STATUSES_LIST));
        const titlesSet = new Set();
        return queryParams.assetsList.flatMap((dataItem) => {
            return (dataItem.acceptableClaimStatuses || [])
                .filter((status) => assetStatusesSet.has(status))
                .map((status) => ({
                    value: status,
                    title: status.split('_').join(' '),
                    color: ASSET_STATUSES_LIST[status].color,
                }))
                .filter((item) => {
                    if (titlesSet.has(item.title)) {
                        return false;
                    } else {
                        titlesSet.add(item.title);
                        return true;
                    }
                });
        });
    }, [queryParams.assetsList]);

    const generateContentFromMenuItemsList = () =>
        menuItemsList &&
        menuItemsList.map((item, ind) => (
            <MenuItemContainer key={`${ind}-${item.title}`}>
                <ChipLabel
                    onClick={() => handleStatusClick(item.value)}
                    label={item.title}
                    style={item.color}
                    disableRipple
                />
            </MenuItemContainer>
        ));

    const fetchData = async () => {
        await getAssetData();
    };

    const changeBulkStatus = async (status, updateStatuses = true) => {
        const bodyParams = {
            excludeAssetIds: queryParams.excludeList.map(({ assetId, clientId }) => ({
                assetId,
                clientId,
            })),
            status,
            updateStatuses,
            ...getFiltersToSend(filtersModel),
        };

        if (!queryParams.downloadAll) {
            bodyParams.includeAssetIds = queryParams.assetsList.map(({ assetId, clientId }) => ({
                assetId,
                clientId,
            }));
            bodyParams.excludeAssetIds = [];
            bodyParams.searchString = filtersModel.searchTerm;
        }

        return isPerformer
            ? changeBulkStatusForPerformer(bodyParams, {
                  cmoId: queryParams.cmoId,
              })
            : changeBulkStatusForRightsHolder(bodyParams, {
                  claimMethod: queryParams.claimMethod,
                  cmoId: queryParams.cmoId,
              });
    };

    const handleModalCancelClick = () => {
        setIsModalOpen(false);
        setModalBodyText(null);
    };

    return (
        <>
            <MuiButton
                variant="outlined"
                onClick={handleOpenBulk}
                color="primary"
                disableElevation
                sx={{
                    height: '35px',
                }}
            >
                BULK UPDATE
                <ArrowDropDownIcon />
            </MuiButton>
            <Menu
                anchorEl={anchorElBulk}
                open={Boolean(anchorElBulk)}
                onClose={handleCloseBulk}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <MenuItemContainer
                    key="bulk-alerts"
                    onClick={handleOpenManualAlertsBulkActionsModal}
                    disabled={!queryParams.assetsList.length}
                >
                    Alerts
                </MenuItemContainer>
                <MenuItemContainer
                    key="bulk-comments"
                    onClick={handleOpenCommentsAddBulkActionsModal}
                    disabled={!queryParams.assetsList.length}
                >
                    Add a comment
                </MenuItemContainer>
                <MenuItemContainer
                    key="bulk-change-status"
                    onClick={handleOpenStatusesMenu}
                    disabled={!queryParams.assetsList.length}
                >
                    Change status
                    <ArrowRightIcon />
                </MenuItemContainer>
                <Menu
                    anchorEl={statusesAnchor}
                    open={Boolean(statusesAnchor)}
                    onClose={handleCloseStatusesMenu}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    {generateContentFromMenuItemsList()}
                </Menu>
            </Menu>
            {isModalOpen && (
                <BulkUpdatesModal
                    title="Confirm bulk update statuses"
                    open={isModalOpen}
                    onClick={handleModalActionClick}
                    bodyText={modalBodyText}
                    onCancel={handleModalCancelClick}
                />
            )}
            {isManualAlertsBulkActionsModalOpen && (
                <AssetsCmoManualAlertsBulkActionsModal
                    isOpen={isManualAlertsBulkActionsModalOpen}
                    setOpen={setIsManualAlertsBulkActionsModalOpen}
                    queryParams={queryParams}
                    getAssetData={getAssetData}
                    isPerformer={isPerformer}
                />
            )}
            {isCommentsModalOpen && (
                <AssetsRegistrationCommentsAddBulkModal
                    queryParams={queryParams}
                    isOpen={isCommentsModalOpen}
                    setOpen={setIsCommentsModalOpen}
                    getAssetData={getAssetData}
                    isPerformer={isPerformer}
                />
            )}
        </>
    );
}
