import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseUsersUrl();

function getAllUsersAPI(queryParams = {}) {
    const url = new URL(`${CONTROLLER_URL}/all`);

    url.search = new URLSearchParams({
        ...(queryParams.page || queryParams.page === 0 ? { page: queryParams.page } : {}),
        ...(queryParams.size ? { size: queryParams.size } : {}),
        ...(queryParams.sort ? { sort: queryParams.sort } : {}),
    });

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to fetch users',
    });
}

function getUsersLookupAPI(username = '', signal) {
    const url = new URL(`${CONTROLLER_URL}/username/lookup`);

    url.search = new URLSearchParams({
        ...(username ? { username } : {}),
    });

    return Fetch.fetchWithErrorToast({
        url,
        signal,
        errorMessage: 'Failed to fetch username lookup',
    });
}

export { getAllUsersAPI, getUsersLookupAPI };
