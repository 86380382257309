import userData from '@data/userData';
import { fetchData } from '@dataUtils/APIUtils';

import { changeStateByNestedKey } from './fugaActions';

const fetchAssets = (url, dispatch, stateType, options = {}) => {
    dispatch(changeStateByNestedKey(stateType, 'tableIsLoading', true));

    options.body = JSON.stringify(options.body);

    fetchData(url, dispatch, stateType, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        ...options,
    });
};

// API calls actions

export function getPerformerAssets(id, queryParams, bodyParams, signal) {
    return (dispatch) => {
        const url = new URL(`${userData.api.baseAssetUrl()}/performer/cmo/${id}`);
        url.search = new URLSearchParams(queryParams);
        fetchAssets(url, dispatch, 'performerCmoPage', { body: bodyParams, signal });
    };
}

export function getRightsHolderAssets(clientId, queryParams, bodyParams, signal) {
    return (dispatch) => {
        if (!queryParams.claimMethod) {
            queryParams.claimMethod = 'AGENT';
        }

        const url = new URL(`${userData.api.baseAssetUrl()}/rh/cmo/${clientId}`);
        url.search = new URLSearchParams(queryParams);

        fetchAssets(url, dispatch, 'cmoPage', { body: bodyParams, signal });
    };
}
